1<template>
  <div class="my-form">
    <div class="templateName" v-if="templateName">模板名称：{{ templateName }}</div>
    <a-steps direction="vertical" :current="stepIndex">
      <a-step v-for="(documentNode, documentNodeIndex) in documentNodeListCopy" :key="documentNode.id">
        <template slot="title">
          <div
            v-if="
              documentNode.sort === 100 &&
              $route.query.todo === 'dealWith' &&
              documentNodeIndex === stepIndex &&
              recipientStatus === 0
            "
          >
            <span style="margin-right: 10px">
              {{ documentNode.name }}
            </span>
            <a-button size="small" disabled type="primary" v-if="!csr_list || csr_list.length === 0"> 抄送 </a-button>
            <a-popconfirm v-else placement="topRight" title="确定抄送？" @confirm="() => carbonCopy(documentNode)">
              <a-button size="small" type="primary"> 抄送 </a-button>
            </a-popconfirm>
          </div>
          <div v-else>
            {{ documentNode.name }}
          </div>
        </template>
        <template slot="description">
          <div>
            <template v-if="documentNode.sort === 100 && recipientStatus === 0">
              <div
                class="left-line left-line-none flex0"
                v-if="$route.query.todo === 'dealWith' && documentNodeIndex === stepIndex"
              >
                <div class="tag-box flex-wrap" style="margin-left: 0; margin-top: 0">
                  <div v-for="(o1, i1) in csr_list" :key="i1" class="flex0">
                    <a-tag class="tags flex0">
                      <OpenData :type="o1.type" :openid="o1.userId || o1.id" :corpid="o1.corpId"></OpenData>
                      <a-button type="link" icon="close" @click="csr_list.splice(i1, 1)" />
                    </a-tag>
                    <a-icon type="arrow-right" class="arrow-right" />
                  </div>
                  <a-button
                    type="primary"
                    icon="plus"
                    ghost
                    class="plus-bt font"
                    @click="showSelctDialog('csr_list', null, null, allList)"
                  >
                  </a-button>
                </div>
              </div>
              <div v-else>
                <div>
                  <a-tag v-for="node in documentNode.nodes" :key="node.id">
                    <OpenData
                      :type="getDataType(node)"
                      :openid="node.userId || node.deptId"
                      :corpid="node.corpId"
                    ></OpenData>
                  </a-tag>
                </div>
              </div>
            </template>
            <template v-else>
              <div class="description-list flex" v-for="node in documentNode.nodes" :key="node.id">
                <template v-if="node.approvalFlag === 2">
                  <div v-if="$route.query.todo !== 'dealWith'">
                    待发起人<OpenData type="userName" :openid="node.userId" :corpid="node.corpId"></OpenData>处理中...
                  </div>
                  <div v-else>
                    发起人处理：
                    <a-popconfirm
                      placement="topRight"
                      title="确认进入下一流程，将不能在下一个流程之前新增审批节点"
                      @confirm="() => dealNext(documentNode.id, node.id)"
                    >
                      <a-button type="primary" size="small" style="margin-right: 10px"
                        >确认流程，并进入下一流程</a-button
                      >
                    </a-popconfirm>
                    <!-- <a-button
                      @click="dealNext(documentNode.id, node.id)"
                      type="primary"
                      size="small"
                      style="margin-right: 10px"
                      >确认流程，并进入下一流程</a-button
                    > -->
                    <a-button type="danger" size="small" @click="addNodes(node, documentNode.id)">加签</a-button>
                  </div>
                </template>
                <template v-else>
                  <div
                    class="name"
                    :class="{
                      check: node.approvalFlag === 1,
                      clock: node.approvalFlag !== 1 && node.approvalFlag !== 0,
                    }"
                  >
                    <OpenData
                      :type="getDataType(node)"
                      :openid="node.userId || node.deptId"
                      :corpid="node.corpId"
                    ></OpenData>
                  </div>
                  <div
                    class="status"
                    v-if="documentNode.sort !== -1"
                    :class="{
                      check: node.approvalFlag === 1 && node.status === 1,
                      clock: node.approvalFlag === 1 && node.status === 2,
                    }"
                  >
                    {{ formatApprovalStatus(node.approvalFlag, documentNode.sort, node.status) }}
                  </div>
                  <div class="date">{{ formatApprovalTime(node.approvalTime) }}</div>
                </template>
              </div>
            </template>
          </div>
        </template>
        <template slot="icon">
          <a-icon type="question-circle" v-if="documentNode.nodeFlag === 0" />
          <a-icon type="check-circle" style="color: #52c41a" v-else-if="documentNode.nodeFlag === 1" />
          <a-icon type="clock-circle" style="color: #f5222d" v-else />
        </template>
      </a-step>
    </a-steps>
    <div class="finish" v-if="approvalStatus !== 0">
      <img src="/images/finish.svg" alt="finish" v-if="approvalStatus === 2" />
      <img src="/images/rejected.svg" alt="rejected" v-else-if="approvalStatus === 3" />
      <img src="/images/going.svg" alt="going" v-else />
    </div>
    <div class="footer" v-if="$route.query.todo === 'accept'">
      <a-button type="primary" @click="acceptBill">接收公文</a-button>
    </div>
    <a-modal title="添加节点信息" :visible="modelVisible" @ok="handleOk" :width="650" @cancel="handleCancel">
      <div class="line-item" v-for="(o, i) in list" :key="i">
        <span class="sp-icon"> <a-icon type="user" /></span>
        <a-input placeholder="请输入" v-model="o.name" readOnly class="input-dom input-dom-edit" />
        <a-button type="link" icon="form" class="edit-icon" @click="editDom" />
        <span class="zusi" v-if="i == 0">（注：点击可修改名称）</span>
        <a-button type="link" icon="delete" @click="delDom(i)" v-if="i > 0" />
        <div class="left-line flex0">
          <div class="tag-box flex-wrap">
            <div v-for="(o1, i1) in o.val" :key="i1" class="flex0">
              <a-tag class="tags flex0">
                <OpenData :type="o1.type" :openid="o1.userId || o1.id" :corpid="o1.corpId"></OpenData>
                <a-button type="link" icon="close" @click="o.val.splice(i1, 1)" />
              </a-tag>
              <a-icon type="arrow-right" class="arrow-right" />
            </div>
            <a-button
              type="primary"
              icon="plus"
              ghost
              class="plus-bt font"
              @click="showSelctDialog('list', i, null, allList)"
            >
            </a-button>
          </div>
        </div>
      </div>

      <div class="line-item">
        <span class="sp-icon"> <a-icon type="plus" /></span>
        添加节点
        <a-button type="primary" shape="circle" icon="plus" @click="addDom" />
        <div class="left-line flex0"></div>
      </div>
    </a-modal>
    <selectPersonDialog ref="selectPersonDialog" @selectTodoMsg="selectTodoMsg" />
  </div>
</template>

<script>
import {
  acceptAddNextNode,
  acceptDocumentBill,
  acceptNextNode,
  carbonCopyBill,
} from '@/api/modular/fileStream/documentManage'
import { selectPerson } from '@/mixins'
import OpenData from '@/views/fileStream/documentManage/weixin/openData'
import selectPersonAndDepart, {
  dealResult,
  getUserListMsg,
} from '@/views/fileStream/documentManage/weixin/selectPerson'
import selectPersonDialog from '@/views/fileStream/documentManage/weixin/selectPersonDialog'
export default {
  props: {
    documentNodeList: {
      type: Array,
      default() {
        return []
      },
    },
    approvalStatus: {
      type: Number,
      default: 0,
    },
    templateName: {
      type: String,
      default: '',
    },
    recipientStatus: {
      type: Number,
      default: 0,
    },
    from: {
      type: String,
      default: '',
    },
  },
  mixins: [selectPerson],
  components: {
    OpenData,
    selectPersonDialog,
  },
  computed: {
    documentNodeListCopy() {
      const documentNodeList = this.finnalyDocumentNodeList.slice()
      const cs = this.documentNodeList.find((j) => j.sort === 100)
      // documentNodeList = documentNodeList.filter(node => node.sort !== -1)
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.csr_list = (cs?.nodes || []).map((j) => ({
        corpId: j.corpId,
        [j.deptId ? 'id' : 'userId']: j.deptId || j.userId,
        type: this.getDataType(j),
      }))
      return documentNodeList
    },
    finnalyDocumentNodeList() {
      const documentNodeList = this.documentNodeList.slice()
      documentNodeList.forEach((j) => {
        if (![-1].includes(j.sort)) {
          this.formatNodeMess(j)
        }
      })
      return documentNodeList
    },
    stepIndex() {
      return this.documentNodeListCopy.findIndex((node) => node.nodeFlag === 0) || 0
    },
    allList() {
      let _list = this.list.map((item) => item.val)
      return [..._list, ...this.csr_list]
    },
  },
  data() {
    return {
      modelVisible: false,
      addNodesLoading: false,
      approvalId: '',
      nodeId: '',
      csr_list: [],
      list: [
        {
          name: '节点',
          val: [],
        },
      ],
    }
  },

  methods: {
    handleCancel() {
      this.modelVisible = false
    },
    formatNodeMess(node) {
      const arr = []
      const getApprovalTime = (deptUsers) => {
        if (deptUsers.some((l) => l.approvalFlag === 0)) return null
        const times = deptUsers.map((user) => {
          const timeStr = user.approvalTime.replace(/-/g, '/')
          const time = new Date(timeStr).getTime()
          return time
        })
        return this.formatApprovalTime(Math.max(times))
      }
      if (node.deptInfos && node.deptInfos.length > 0) {
        node.deptInfos.forEach((dep) => {
          arr.push({
            deptId: dep.deptId,
            deptUsers: dep.deptUsers,
            corpId: dep.deptUsers[0].corpId,
            approvalTime: getApprovalTime(dep.deptUsers),
            status: Number(!dep.deptUsers.some((l) => l.status === 0)),
            approvalFlag: Number(!dep.deptUsers.some((l) => l.approvalFlag === 0)),
          })
        })
      }
      let array = []
      array = array.concat(
        [...node.nodes, ...arr].filter((j) => j.approvalFlag === 1),
        [...node.nodes, ...arr].filter((j) => j.approvalFlag !== 1)
      )
      node.nodes = array
    },
    getDataType(node) {
      return node.deptId ? 'departmentName' : 'userName'
    },
    addNodes({ id }, nodeId) {
      this.approvalId = id
      this.nodeId = nodeId
      this.modelVisible = true
    },
    /**
     * 接收公文
     */
    acceptBill() {
      acceptDocumentBill(this.$route.query.id)
        .then((res) => {
          if (res.code === 200) {
            this.$message.success('接收成功')
            this.$nextTick(() => {
              this.$router.go(-1)
            })
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    carbonCopy({ id }) {
      carbonCopyBill({
        nodeId: id,
        userList: this.csr_list.map(({ userId, corpId, id, type }) => ({
          [this.getIdKey(type)]: userId || id,
          corpId,
        })),
      })
        .then((res) => {
          console.log(res)
          if (res.code === 200) {
            this.$message.success('抄送成功')
            this.$nextTick(() => {
              this.$router.go(-1)
            })
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    getIdKey(type) {
      return type === 'departmentName' ? 'departmentId' : 'userId'
    },
    handleOk() {
      if (this.list.some((j) => !j.val || j.val.length === 0)) {
        this.$message.warning('存在节点没有选择人员！')
        return
      }
      if (this.addNodesLoading) return
      this.addNodesLoading = true
      const documentNodeParams = this.list.map((el, index) => ({
        billId: this.$route.query.id,
        sort: index,
        name: el.name,
        approvals: el.val.map(({ corpId, userId, id, type }) => ({
          [this.getIdKey(type)]: userId || id,
          corpId: corpId,
        })),
      }))
      acceptAddNextNode({
        documentNodeParams,
        approvalId: this.approvalId,
        nodeId: this.nodeId,
      })
        .then((res) => {
          console.log(res)
          if (res.code === 200) {
            this.$message.success('添加成功')
            this.$nextTick(() => {
              this.$router.push({ path: '/fileStreamDocumentManage', replace: true })
              // this.$router.go(-1)
            })
          }
        })
        .catch((err) => {
          console.log(err)
        })
        .finally(() => {
          this.modelVisible = false
          this.addNodesLoading = false
        })
    },
    delDom(i) {
      this.list.splice(i, 1)
    },
    dealNext(id, nodeId) {
      acceptNextNode({
        billId: this.$route.query.id,
        nodeId: id,
        approvalId: nodeId,
      })
        .then((res) => {
          if (res.code === 200) {
            this.$message.success('流程确认成功！')
            this.$nextTick(() => {
              this.$router.push({ path: '/fileStreamDocumentManage', replace: true })
            })
          } else {
            this.$message.warning(res.message)
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    editDom() {
      const dom = arguments[0].target.parentElement.getElementsByClassName('input-dom')[0]
      dom.removeAttribute('readOnly')
      dom.classList.remove('input-dom-edit')
    },
    formatApprovalTime(approvalTime) {
      if (!approvalTime) return ''
      const date = new Date(approvalTime)
      const dateStr = date.toLocaleString()
      return dateStr.replace(/\//g, '-')
    },
    choosePerson(list, index) {
      let val
      if (list === 'list') {
        val = [...this[list][index].val]
      } else {
        val = [...this[list]]
      }
      const {
        selectedOpenUserIds,
        selectedCorpGroupUserIds,
        selectedDepartmentIds,
        selectedCorpGroupDepartmentIds,
        isList,
      } = getUserListMsg(list, index, val)
      selectPersonAndDepart({
        selectedOpenUserIds,
        selectedCorpGroupDepartmentIds,
        selectedDepartmentIds,
        selectedCorpGroupUserIds,
      })
        .then((res) => {
          const { result, resPart } = dealResult(res)
          let isUserNode =
            this.confineNodeSelectByChoosePerson && this.confineNodeSelectByChoosePerson(result, this[list])
          if (isList) {
            isUserNode && this.$set(this[list][index], 'val', [...result, ...resPart])
          } else {
            isUserNode && this.$set(this, list, [...result, ...resPart])
          }
        })
        .catch((err) => {
          console.log(err)
        })
      // this.$refs.sp_user.add(list, index)
    },
    addDom() {
      this.list.push({ name: '节点', val: [] })
    },
    formatApprovalStatus(approvalFlag, sort, status) {
      if (sort === 99) {
        return approvalFlag ? '已接收' : '待接收'
      }
      if (sort === 100) {
        return approvalFlag ? '已通知' : '待通知'
      }
      if (approvalFlag === 0) {
        return '待审核'
      } else if (approvalFlag === 3) {
        return '同级已审'
      } else if (status === 1) {
        return '审核通过'
      } else {
        return '驳回'
      }
    },
  },
}
</script>

<style lang="less" scoped>
.flex {
  display: flex;
  justify-content: space-between;
}
.flex0 {
  display: flex;
}
.font {
  font-size: 16px;
}

.description-list {
  align-items: center;
  justify-content: flex-start;
  .name {
    font-size: 14px;
    color: #000000;
    margin-right: 20px;
    &.check {
      color: #52c41a;
    }
    &.clock {
      color: #f5222d;
    }
  }
  .status {
    margin-right: 20px;
    &.check {
      color: #52c41a;
    }
    &.clock {
      color: #f5222d;
    }
  }
  .date {
    font-size: 12px;
    color: #3e3d3d;
  }
}

.my-form {
  background: #fff;
  padding: 20px 25px;
  padding-top: 94px;
  .templateName {
    font-size: 16px;
    color: #1890ff;
    margin-bottom: 10px;
  }
}
.mitem {
  .mimg {
    position: relative;
    img {
      width: 60px;
      height: 60px;
      background: #a9a9a9;
      border-radius: 4px;
    }
    .sp-ic {
      width: 21px;
      height: 21px;
      background: #3b9afa;
      border: 1px solid #ffffff;
      border-radius: 50%;
      display: inline-block;
      text-align: center;
      position: absolute;
      left: 42px;
      bottom: -2px;
      .img-ic {
        color: white;
      }
    }
  }
  .text {
    margin-left: 10px;
    width: 100%;
    .title {
      font-size: 14px;
      color: #000000;
      span {
        font-size: 12px;
        color: #3e3d3d;
        float: right;
      }
    }
    .fqr {
      font-size: 12px;
      color: #3e3d3d;
      margin-top: 10px;
    }
  }
}
.mline {
  width: 1px;
  height: 46px;
  background: #d3d3d3;
  margin: 5px 30px;
}
.flex {
  display: flex;
  justify-content: space-between;
}
.flex0 {
  display: flex;
  align-items: center;
}
.font {
  font-size: 16px;
}

.my-form {
  width: 478px;
  margin-left: 10px;
  background: #fff;
  // padding: 20px 25px;
}
::v-deep .ant-form-item-label {
  text-align: left;
}

.line {
  height: 1px;
  border: 1px dashed #3b9afa;
  display: block;
  width: 100%;
  margin: 30px 0;
}
.plus-bt {
  width: 46px;
  height: 46px;
  background: #e9f2fe;
  border-radius: 4px;
  margin: 10px 0;
}
.left-line::before {
  content: '';
  height: 80px;
  position: relative;
  top: 0px;
  left: 13px;
  width: 2px;
  background: #cdcfd1;
}
.left-line-last:before {
  background: white;
}
.left-line-none::before {
  display: none;
}
.sp-icon {
  width: 26px;
  height: 26px;
  background: #cdcfd1;
  border-radius: 50%;
  display: inline-block;
  text-align: center;
  color: white;
  line-height: 24px;
}
.submit-bt {
  text-align: right;
  margin: 40px 0 40px;
  button {
    width: 100px;
    height: 36px;
    background: #3b9afa;
    border-radius: 4px;
    margin-right: 10px;
  }
}
.edit-icon {
  margin-left: 10px;
}
.zusi {
  font-size: 12px;
  color: #acacac;
}
.input-dom {
  width: 100px;
  margin-left: 6px;
}
.input-dom-edit {
  border: none;
}
.input-dom-edit:hover {
  border: none;
}
.input-dom-edit:focus {
  border: none;
}
.tags {
  height: 46px;
  background: #f2f2f3;
  border: none;
  margin-bottom: 10px;
  button {
    margin: 0;
  }
}
.flex-wrap {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.tag-box {
  margin: 10px 0 10px 30px;
}
.arrow-right {
  font-size: 0.6em;
  color: #aaa;
  margin-right: 10px;
}
.finish {
  text-align: center;
  margin-top: 30px;
  line-height: 96px;
  height: 96px;
  img {
    width: 86px;
  }
}
</style>
